<script setup>
import { ref, computed, inject } from "vue";
import axios from "../public/baseurl/backendbaseurl";
// import axios from "@/gateway/backendapi";
import router from "../../../router";
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
// import BkDellIcon from "../../../assets/ecommerce/BKDell.png";
// import HindsightIcon from "../../../assets/ecommerce/HindSight.png";
// import TempsIcon from "../../../assets/ecommerce/Temps.png";
import { useStore } from "vuex";
import { ElLoading } from "element-plus";
import { ElMessage } from "element-plus";
// import { Search } from "@element-plus/icons-vue";
// const selectedCurrency = ref({});
const orderData = ref({});
const loading = ref(false);
const paymentDone = ref(false);
const paymentFailed = ref(false);
const isLoggedIn = ref(false);
const isSignUpIn = ref(false);
const primarycolor = inject("primarycolor");
const cartItems = ref(JSON.parse(localStorage.getItem("cartItems")) || []);

// const searchQuery = ref("");
const paymentGatewayModal = ref(false);
const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();
const store = useStore();
const tenantId = computed(() => store.getters["ecommerce/getId"]);
const sessionTennantId = ref(sessionStorage.getItem("tenantId"));
const userId = ref(localStorage.getItem("userId"));

const currentUser = computed(() => {
  if (
    !store.getters.currentUser ||
    (store.getters.currentUser && Object.keys(store.getters.currentUser).length == 0)
  )
    return "";
  return store.getters.currentUser;
});

const paymentSuccessful = () => {
  router.push(
    `/store/home?id=${tenantId.value ? tenantId.value : sessionTennantId.value}`
  );
};

const addOrder = async () => {
  loading.value = true;
  let payload = {
    totalAmount: subtotal.value,
    mobileUserID: userId.value,
    orderItems: cartItems.value.map((i) => {
      return {
        ecommerceProductID: i.id,
        quantity: i.quantity,
        priceAtPurchase: i.price,
      };
    }),
  };

  isLoggedIn.value = localStorage.getItem("loggedIn");
  isSignUpIn.value = localStorage.getItem("signedUp");
  if (isLoggedIn.value || isSignUpIn.value) {
    try {
      const { data } = await axios.post(
        `/Ecommerce/AddOrder/${tenantId.value ? tenantId.value : sessionTennantId.value}`,
        payload
      );
      console.log(data, "");
      orderData.value = data;
      if (data) {
        paymentGatewayModal.value = true;
      } else {
        paymentGatewayModal.value = false;
      }

      loading.value = false;
    } catch (error) {
      loading.value = false;
      console.log(error);
    }
    // router.push('/store/checkout')
    // paymentGatewayModal.value = true;
  } else {
    router.push("/store/login");
  }
};
const tryAgain = () => {
  paymentFailed.value = false;
};
// const showPaymentGateway = () => {

// };

const initializePayment = (paymentGateway) => {
  const loading = ElLoading.service({
    lock: true,
    text: "Please wait...",
    background: "rgba(255, 255, 255, 0.9)",
  });

  // const payload = {
  //   subscriptionPlanID: selectedSubscriptionPlan.value.id,
  //   paymentGateway: paymentGateway === 0 ? "Paystack" : "Flutterwave",
  //   totalAmount: orderData.value.totalAmount.value,
  //   orderId: orderData.value.id,
  //   tenantId: orderData.value.tenantID,
  //   currencyId: selectedCurrency.value.id,
  //   orderItems: orderData.value.orderItems.map((i) => ({
  //         id: i.id,
  //         ecommerceOrderID: i.ecommerceOrderID,
  //         ecommerceProductID: i.ecommerceProductID,
  //         priceAtPurchase: i.priceAtPurchase,
  //         quantity: i.quantity,
  //       })),
  // };
  axios
    .get(
      `/InitializeEcommercePayment?orderId=${orderData.value.id}&tenantId=${
        orderData.value.tenantID
      }&paymentGateway=${paymentGateway === 0 ? "Paystack" : "Flutterwave"}`
    )
    .then(({ data }) => {
      console.log(data, "sgshhsh");
      // close.value.click();
      // initializedOrder.value = res.data;
      loading.close();
      if (data.status) {
        if (paymentGateway == 0) {
          payWithPaystack(data);
        } else {
          payWithFlutterwave(data);
        }
      }
    });
};
const loadFlutterwaveScript = () => {
  return new Promise((resolve, reject) => {
    if (typeof window.FlutterwaveCheckout !== "undefined") {
      resolve(true);
    } else {
      const script = document.createElement("script");
      script.src = "https://checkout.flutterwave.com/v3.js";
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error("Flutterwave script failed to load"));
      document.head.appendChild(script);
    }
  });
};

const payWithFlutterwave = async (responseObject) => {
  console.log(responseObject, "flutterwave");

  // let country = "";

  // switch (selectedCurrency.value.shortCode) {
  //   case "KES":
  //     country = "KE";
  //     break;
  //   case "GHS":
  //     country = "GH";
  //     break;
  //   case "ZAR":
  //     country = "ZA";
  //     break;
  //   case "TZS":
  //     country = "TZ";
  //     break;

  //   default:
  //     country = "NG";
  //     break;
  // }
  try {
    await loadFlutterwaveScript(); // Ensure the script is loaded before using FlutterwaveCheckout

    window.FlutterwaveCheckout({
      public_key: process.env.VUE_APP_FLUTTERWAVE_TEST_KEY,
      // public_key: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY_LIVE,
      tx_ref: responseObject.transactionReference,
      amount: responseObject.amount,
      currency: responseObject.tenant.country.currency,
      country: responseObject.tenant.country.name,
      payment_options: "card,ussd",
      customer: {
        name:
          responseObject && responseObject.tenant
            ? responseObject.tenant.name
            : currentUser.value.churchName,
        email:
          responseObject && responseObject.tenant
            ? responseObject.tenant.email
            : currentUser.value.userEmail,
      },
      callback: (response) => {
        let trans_id = response.transaction_id;
        let tx_ref = response.tx_ref;
        subscriptionPayment(trans_id, tx_ref);
      },
      onclose: () => console.log("Payment closed"),
      customizations: {
        title: "Subscription",
        description: "Payment for Subscription",
      },
    });
  } catch (error) {
    console.error("Flutterwave script error:", error);
  }

  // window.FlutterwaveCheckout({
  //   public_key: process.env.VUE_APP_FLUTTERWAVE_TEST_KEY,
  //   public_key: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY_LIVE,
  //   tx_ref: responseObject.transactionReference,
  //   amount: responseObject.amount,
  //   currency: responseObject.tenant.country.currency,
  //   country: responseObject.tenant.country.name,
  //   payment_options: "card,ussd",
  //   customer: {
  //     name: responseObject && responseObject.tenant ? responseObject.tenant.name : currentUser.value.churchName,
  //     email: responseObject && responseObject.tenant ? responseObject.tenant.email : currentUser.value.userEmail,
  //   },
  //   callback: (response) => {
  //     let trans_id = response.transaction_id;
  //     let tx_ref = response.tx_ref;
  //     subscriptionPayment(trans_id, tx_ref);
  //   },
  //   onclose: () => console.log("Payment closed"),
  //   customizations: {
  //     title: "Subscription",
  //     description: "Payment for Subcription ",
  //     logo: churchLogo.value,
  //   },
  // });
};

const payWithPaystack = (responseObject) => {
  /*eslint no-undef: "warn"*/
  let handler = PaystackPop.setup({
    key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY_LIVE,
    // key: process.env.VUE_APP_PAYSTACK_API_KEY,
    email: "info@churchplus.co",
    amount: responseObject.amount * 100,
    // amount: TotalAmount.value * 100,
    // currency: selectedCurrency.value.shortCode,
    currency: responseObject.tenant.country.currency,
    // country: responseObject.tenant.country.name,
    channels: ["card", "bank", "ussd", "qr", "mobile_money", "bank_transfer"],
    ref: responseObject.transactionReference,
    onClose: function () {
      ElMessage({
        type: "info",
        showClose: true,
        message: "You have cancelled the transaction",
        duration: 5000,
      });
    },
    callback: function (response) {
      let trans_id = response.trxref;
      let tx_ref = response.trxref;
      subscriptionPayment(tx_ref, trans_id);
    },
  });
  handler.openIframe();
};
const subscriptionPayment = async (trans_id, tx_ref) => {
  try {
    await axios
      .post(`/ConfirmEcommercePayment?id=${trans_id}&txnref=${tx_ref}`)
      .then((res) => {
        console.log(res);
        // display.value = true;
        if (res.data) {
          paymentFailed.value = false;
          paymentDone.value = true;
          localStorage.removeItem("cartItems"); // Clear cart items (optional)
        } else {
          paymentFailed.value = true;
        }
      })
      .catch((err) => {
        console.log(err);
        // display.value = true;
        paymentFailed.value = true;
        paymentDone.value = false;
      });
  } catch (error) {
    console.log(error);
    paymentDone.value = false;
    paymentFailed.value = true;
    ElMessage({
      type: "error",
      showClose: true,
      message: "Transaction Failed",
      duration: 5000,
    });
  }
};

const increment = (item) => {
  item.quantity++;
};

const decrement = (item) => {
  if (item.quantity > 1) {
    item.quantity--;
  }
};

// const goBack = () => {
//   // Logic to navigate back
//   window.history.back();
// };

const removeItem = (id) => {
  cartItems.value = cartItems.value.filter((item) => item.id !== id);
  store.dispatch("ecommerce/removeItemFromCart", id);
  store.dispatch("ecommerce/setCartCount", cartItems.value.length);
  localStorage.setItem("cartItems", JSON.stringify(cartItems.value));
};

const subtotal = computed(() => {
  return cartItems.value.reduce((acc, item) => acc + item.price * item.quantity, 0);
});
</script>
<template>
  <!-- <div class="row">
    <div class="col-md-12 d-flex bg-black-2 py-4">
      <div
        class="d-flex col-md-3 align-items-center justify-content-center mr-2 text-white"
        @click="goBack"
      >
        <el-icon :size="25"><Back /></el-icon>
        <span class="ml-1">Back</span>
      </div>
      <div class="col-md-6 ml-3">
        <el-input
          class="w-100 rounded-border"
          :suffix-icon="Search"
          placeholder="Search items product & services"
          v-model="searchQuery"
          size="large"
        />
      </div>
    </div>
  </div> -->
  <div class="container-fluid my-5" :class="{ 'container-slim': lgAndUp || xlAndUp }">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-between">
        <h2>My Cart</h2>
        <span class="s-24 fw-400"
          >Total item in cart :
          <span class="badge badge-pill text-white" style="background: #ff4f19">{{
            cartItems.length
          }}</span></span
        >
      </div>
    </div>
    <div class="card py-2 px-4  mt-3 bg-gray-500 mb-3" v-for="item in cartItems" :key="item.id">
      <div class="row no-gutters align-items-center">
        <div class="col-md-2">
          <img :src="item.image || item.imageURL" class="card-img" alt="" />
        </div>
        <div class="col-md-6">
          <div class="card-body">
            <h5 class="card-title fw-500 s-24">{{ item.title }}</h5>
            <p class="card-text fw-400 s-24">
              <img src="../../../assets/ecommerce/audioBookIcon.png" alt="" /> AudioBook
            </p>
            <div class="input-group col-md-12 align-items-center">
              <div class="fw-400 s-24 mr-1">Quantity</div>
              <div
                class="input-group-prepend cursor-pointer p-2"
                @click="increment(item)"
              >
                <div>+</div>
              </div>
              <div>
                <div class="border rounded px-3 py-2 mx-2">{{ item.quantity }}</div>
              </div>
              <div
                class="input-group-prepend cursor-pointer p-2"
                @click="decrement(item)"
              >
                <div>-</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex flex-column align-items-end justify-content-between">
          <div class="cursor-pointer text-danger" @click="removeItem(item.id)">
            <el-icon :size="20"><Delete /></el-icon>
          </div>
          <div class="mt-5">
            <p class="mb-0">
              <span class="s-32 fw-500 text-dak">NGN {{ Math.abs(item.price).toLocaleString() }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4 d-flex justify-content-between">
        <div class="text-dak fw-500 s-24">Shipping</div>
        <div>NGN 0</div>
      </div>
      <div class="col-md-12 d-flex justify-content-between">
        <div class="text-dak fw-500 s-24">Discount</div>
        <div>NGN 0</div>
      </div>
      <div class="col-md-12 d-flex justify-content-between">
        <div class="text-dak font-weight-bold s-24">SubTotal</div>
        <div class="font-weight-bold s-24">NGN {{ Math.abs(subtotal).toLocaleString() }}</div>
      </div>
      <!-- <div class="col-md-12 mt-4 d-flex justify-content-between">
        <el-input v-model="coupon" placeholder="coupon" class="w-100">
          <template #append>
            <div>
              <el-button size="large" color="#0A0909" class=" py-4 text-white w-100">Apply</el-button>
            </div>
          </template>
        </el-input>
      </div> -->
      <!-- <div class="col-md-12 d-flex justify-content-center">
        <div
          class="col-md-4 bg-gray-500 d-flex justify-content-center align-items-center border-radius-border-8 py-3 my-4"
        >
          <h4 class="mt-2">SubTotal : NGN {{ subtotal }}</h4>
        </div>
      </div> -->
      <div class="col-md-12 d-flex mt-4 justify-content-center">
        <div class="col-md-4 px-0">
          <div @click="addOrder">
            <el-button
              :loading="loading"
              round
              color="#FF5906"
              class="w-100 py-4 text-white"
              >Checkout</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-dialog
      class=""
      style="border-radius: 25px"
      v-model="paymentGatewayModal"
      title=""
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`"
    >
      <div class="row justify-content-center">
        <div class="col-md-10 col-11 mt-4 h-100 bg-white mb-5">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-10 d-flex justify-content-center">
              <div class="text-head font-weight-bold h3 pl-2">Payment methods</div>
            </div>
            <div class="col-md-12 mt-2 d-flex justify-content-center">
              <div class="col-md-7 col-12 col-sm-8">
                <div class="col-sm-12 p-4 text-center continue-text">
                  Continue payment with
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-2">
              <div
                class="row justify-content-center c-pointer"
                @click="initializePayment(0)"
              >
                <!-- <div
                class="row justify-content-center c-pointer"
                @click="initializePayment(0)"
                v-if="currentUser.currency == 'NGN' || currentUser.currency == 'GHS'"
              > -->
                <div class="col-md-8 border py-3 row-button">
                  <div class="row c-pointer justify-content-center">
                    <div class="col-md-6">
                      <img
                        class="w-100"
                        src="../../../assets/mobileonboarding/paystack.png"
                        alt="paystack"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row c-pointer mt-3 justify-content-center"
                @click="initializePayment(1)"
              >
                <div class="col-md-8 border py-2 row-button">
                  <div class="row c-pointer justify-content-center">
                    <div class="col-md-7">
                      <img
                        class="w-100"
                        src="../../../assets/mobileonboarding/flutterwave.png"
                        alt="flutterwave"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      v-model="paymentDone"
      style="border-radius: 20px"
      title=""
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : xsOnly ? `90%` : `70%`"
      align-center
    >
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <img
              src="../../../assets/successful_payment.png"
              style="width: 250px; margin: auto"
            />
          </div>
          <h3 class="text-center mt-5 font-weight-bold success">Thank you</h3>
          <div class="text-center mt-2 font-weight-600 s-18">
            Payment completed successfully
          </div>
          <div class="d-flex justify-content-center mb-5">
            <el-button
              color="#70c043"
              class="text-white mt-2"
              @click="paymentSuccessful"
              round
              >Go back</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class=""
      style="border-radius: 25px"
      v-model="paymentFailed"
      title=""
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`"
    >
      <div class="row justify-content-center">
        <div class="col-md-10 mt-4 h-100 bg-white mb-5">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-10 d-flex justify-content-center">
              <div class="col-md-6">
                <img
                  class="w-100"
                  src="../../../assets/mobileonboarding/sub-failed.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <div class="col-md-6 col-8">
                <div
                  class="text-head font-weight-bold col-md-12 px-0 h2 text-center"
                  style="color: #d21615"
                >
                  Payment failed!
                </div>
              </div>
            </div>
            <div class="col-md-10 d-flex my-5 justify-content-center">
              <div class="col-md-8">
                <el-button
                  @click="tryAgain"
                  :color="primarycolor"
                  size="large"
                  class="w-100"
                  round
                  >Try again</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.card-img {
  max-width: 100%;
  height: auto;
}

.quantity-group {
  max-width: 150px;
}
.card-text img {
  height: 2.2rem;
  width: 2.2rem;
}

.rounded-border div .el-input__wrapper {
  padding: 6px 11px !important;
  background: #fcfcfc !important;
  border-radius: 60px !important;
  box-shadow: none !important;
}
.continue-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.row-button {
  /* padding: 10px; */
  /* padding: 10px 0; */
  border-radius: 50px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  background: #f3f3f3;
  /* background: #fff; */
  /* margin: 12px 70px 15px 70px; */
  transition: all 0.4s ease-in-out;
  border: 1px solid #0000002b;
  /* max-height: 45px; */
}
</style>